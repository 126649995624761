<template>
  <main>
    <make-banner :banner="config.pc_login_logo">
      <div class="login-box core row-end">
        <div class="login">
          <div class="change-title xxl">
            <span class="active">忘记密码</span>
          </div>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
            <el-form-item class="form-input" prop="true_name">
              <div class="form-title row xs lighter">
                <img
                  src="@/assets/icon/icon-user.png"
                  alt=""
                  style="opacity: 0.4"
                />
                <span>真实姓名</span>
              </div>
              <el-input
                v-model="ruleForm.true_name"
                placeholder="请填写真实的姓名"
              ></el-input>
            </el-form-item>
            <el-form-item class="form-input" prop="mobile">
              <div class="form-title row xs lighter">
                <img src="@/assets/icon/icon-tel1.png" alt="" />
                <span>手机号</span>
              </div>
              <el-input
                v-model="ruleForm.mobile"
                placeholder="注册手机号"
                type="text"
              ></el-input>
            </el-form-item>
            <div class="row-between" style="margin-bottom: 22px">
              <el-form-item class="form-input code" prop="code">
                <el-input
                  v-model="ruleForm.code"
                  placeholder="请输入验证码"
                ></el-input>
              </el-form-item>
              <div
                @click="countDown"
                class="get-code lighter row-center"
                :class="{ disabled: !this.canClick }"
              >
                {{ content }}
              </div>
            </div>
            <el-form-item class="form-input" prop="password">
              <div class="form-title row xs lighter">
                <img src="@/assets/icon/icon-pwd1.png" alt="" />
                <span>设置密码</span>
              </div>
              <el-input
                v-model="ruleForm.password"
                type="password"
                placeholder="建议6-20位字母和数字等组合"
              ></el-input>
            </el-form-item>
            <el-form-item class="form-input" prop="repassword">
              <div class="form-title row xs lighter">
                <img src="@/assets/icon/icon-pwd1.png" alt="" />
                <span>确认密码</span>
              </div>
              <el-input
                v-model="ruleForm.repassword"
                placeholder="请输入确认密码"
                type="password"
              ></el-input>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')">{{
                loginRequest ? "修改中" : "修改"
              }}</el-button>
            </el-form-item>
            <div class="row-between">
              <router-link to="/register" class="normal register"
                >用户注册</router-link
              ><router-link to="/" class="normal register"
                >返回登录</router-link
              >
            </div>
          </el-form>
        </div>
      </div>
    </make-banner>
  </main>
</template>
<script>
import MakeBanner from "@/components/MakeBanner";
import { vxRule, rules } from "@/utils/validator";
import { mapMutations, mapState } from "vuex";
export default {
  name: "",
  props: {},
  components: {
    "make-banner": MakeBanner,
  },
  data() {
    // 密码验证
    var validatePwdContrast = (rule, value, callback) => {
      if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        true_name: "", //用户名称
        mobile: "", //电话号码
        code: "", //验证码
        password: "", //密码
        repassword: "", //重置密码
      },
      loginType: 1,
      content: "发送验证码",
      totalTime: 60,
      canClick: true, //添加canClick
      loginRequest: false,
      rules: {
        true_name: vxRule(true, null, "blur", "请填写真实的姓名"),
        mobile: vxRule(true, "Mobile", "blur", "请填写手机号码"),
        code: vxRule(true, null, "blur", "请输入验证码"),
        password: vxRule(true, "Password", "blur", "请输入验证码"),
        repassword: [
          { required: true, message: "请再次输入密码", trigger: "blur" },
          { validator: validatePwdContrast, trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    ...mapState(["config"]),
  },
  watch: {},
  methods: {
    ...mapMutations("user", ["SET_USER_INFO"]),
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.loginRequest) return;
          this.loginRequest = true;
          let { code, msg } = await this.$api.forgetPasswordApi(this.ruleForm);
          this.loginRequest = false;
          if (code == 1) {
            this.$message({
              message: msg,
              type: "success",
              onClose: () => {
                this.$router.replace("/login");
              },
            });
          }
        } else {
          return false;
        }
      });
    },
    countDown() {
      if (!this.canClick) return; //改动的是这两行代码
      let { result, errMsg } = rules.Mobile(this.ruleForm.mobile);
      if (!result) {
        this.$message.error(errMsg);
        return;
      }
      this.canClick = false;
      this.content = this.totalTime + "s后重新发送";
      this.$api.getSmsSendApi({
        mobile: this.ruleForm.mobile,
        key: "ZHMM",
      });
      let clock = window.setInterval(() => {
        this.totalTime--;
        this.content = this.totalTime + "s后重新发送";
        if (this.totalTime < 0) {
          window.clearInterval(clock);
          this.content = "重新发送验证码";
          this.totalTime = 60;
          this.canClick = true; //这里重新开启
        }
      }, 1000);
    },
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang='scss' scoped>
.login-box {
  padding: 60px 90px 90px 0;
  .login {
    width: 435px;
    background-color: $-color-white;
    padding: 38px 38px 0;
    .change-title {
      color: #b0b0b0;
      margin-bottom: 37px;
      cursor: pointer;
      .active {
        color: $-color-normal;
      }
    }
  }
  .form-title {
    width: 120px;
    position: relative;
    flex-shrink: 0;
    &::after {
      content: "";
      position: absolute;
      right: 0;
      height: 35px;
      width: 1px;
      top: 50%;
      transform: translateY(-50%);
      background: $-color-border;
    }
    img {
      width: 30px;
      height: 30px;
      margin-left: 7px;
      margin-right: 5px;
    }
  }
  .forget {
    margin-bottom: 15px;
    /deep/ .el-form-item__content {
      height: 20px;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &::before,
      &::after {
        display: none;
      }
      .el-checkbox-group {
        display: inherit;
      }
    }
  }
  .get-code {
    width: 133px;
    height: 54px;
    background: #ededed;
    flex-shrink: 0;
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
    }
  }
  .form-input {
    &.code {
      margin-bottom: 0;
      width: 218px;
    }
    /deep/ .el-form-item__content {
      display: flex;
      align-items: center;
      height: 54px;
      line-height: 54px;
      border: $-solid-border;
      .el-input__inner {
        border: none;
      }
    }
    img {
      width: 15px;
      margin: 0 10px;
      height: auto;
    }
  }
  /deep/ .el-button {
    background-color: $-color-primary;
    border-color: $-color-primary;
    border-radius: 0;
    width: 100%;
    height: 54px;
  }
  .register {
    margin-top: -10px;
    margin-bottom: 30px;
    display: block;
  }
}
</style>